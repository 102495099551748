import React from 'react';
import { TiptapTextEditor } from 'src/components/WidgetMaker/WidgetDnD/components/TiptapTextEditor/TiptapTextEditor';
import { BubbleMenu } from '@tiptap/react';
import { ToolbarMenu } from 'src/components/WidgetMaker/WidgetDnD/components/TiptapTextEditor/ToolbarMenu';

export function EditableText({
  content = '',
  maxLength = undefined,
  disabled = true,
  className = '',
}) {
  return (
    <TiptapTextEditor
      content={content}
      // onTextUpdate={({text}) => onChange('subHeading', text)}
      maxLength={maxLength}
      disabled={disabled}
      className={className}
      renderToolbarProps={(editor) => {
        return (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            shouldShow={(props) => {
              return props.editor.isFocused;
            }}
          >
            <div className="floating-menu">
              <ToolbarMenu editor={editor} showFormattors={false} />
            </div>
          </BubbleMenu>
        );
      }}
    />
  );
}
